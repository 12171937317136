import { gql } from '@apollo/client'
import OrganizationParts from 'graphql/fragments/OrganizationParts'

export default gql`
  query RootOrganizations($first: Int, $offset: Int, $orderBy: [_OrganizationOrdering]) {
    RootOrganizations(first: $first, offset: $offset, orderBy: $orderBy) {
      id
      ...OrganizationParts
    }
    RootOrganizationsCount
  }
  ${OrganizationParts}
`
