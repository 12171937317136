import { useQuery } from '@apollo/client'
import NotFound from 'components/NotFound'
import SelectWorkspacePage from 'components/SelectWorkspacePage'
import { WorkspaceBySlug, WorkspaceBySlugVariables, WorkspaceParts } from 'generatedTypes'
import WorkspaceBySlugQuery from 'graphql/queries/WorkspaceBySlugQuery'
import useSubdomain from 'hooks/useSubdomain'
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

interface WorkspaceProviderValue {
  workspace?: WorkspaceParts
  loadingWorkspace: boolean
  changeWorkspace: (slug: string) => void
  changeAdminWorkspace: (slug?: string) => void
}

const WorkspaceContext = createContext<WorkspaceProviderValue>({
  workspace: undefined,
  loadingWorkspace: true,
  changeWorkspace: () => null,
  changeAdminWorkspace: () => null,
})

const useWorkspace = () => useContext(WorkspaceContext)

const WorkspaceProvider: FC = ({ children }) => {
  const subdomain = useSubdomain()
  const [localStorageSlug, setLocalStorageSlug] = useLocalStorage<string>('workspace_slug', '', { raw: true })
  const [, setAdminLocalStorageSlug] = useLocalStorage<string>('admin_workspace_slug', '', { raw: true })

  const [slug, setSlug] = useState<string>()
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setSlug(localStorageSlug || 'test')
    } else if (subdomain && subdomain !== 'localhost') {
      setSlug(subdomain)
    } else {
      setSlug(undefined)
    }
  }, [subdomain, localStorageSlug])

  const { data, loading, error } = useQuery<WorkspaceBySlug, WorkspaceBySlugVariables>(WorkspaceBySlugQuery, {
    variables: { slug: slug || '' },
    skip: !slug,
  })
  const workspace = data?.WorkspaceBySlug || undefined

  const changeWorkspace = useCallback(
    (slug: string) => {
      if (process.env.NODE_ENV === 'development') {
        setLocalStorageSlug(slug)
      } else {
        window.location.replace('https://' + slug + '.' + process.env.REACT_APP_REDIRECT_URL)
      }
    },
    [setLocalStorageSlug]
  )

  return (
    <WorkspaceContext.Provider
      value={{ workspace, changeAdminWorkspace: setAdminLocalStorageSlug, loadingWorkspace: loading || !slug, changeWorkspace }}
    >
      {!workspace && !loading && slug && !error && <SelectWorkspacePage />}
      {error && <NotFound />}
      {!error && !loading && workspace && children}
    </WorkspaceContext.Provider>
  )
}

export { WorkspaceProvider, WorkspaceContext, useWorkspace }
