import { AnimatePresence, motion } from 'framer-motion'
import React, { FC } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useKey } from 'react-use'
import usePortal from 'react-useportal'

interface Props {
  open: boolean
  side?: 'left' | 'right'
  className?: string
  onClose: () => void
}

const SlideOver: FC<Props> = ({ open, side = 'left', className = '', onClose, children }) => {
  const { Portal } = usePortal({
    bindTo: document?.getElementById('modal-root') || undefined,
  })

  const slideOverRef = useOnclickOutside(onClose, { ignoreClass: 'clickaway-ignore' })
  useKey('Escape', onClose)

  return (
    <Portal>
      <AnimatePresence>
        {open && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ zIndex: 1000 }}
              transition={{ duration: 0.2 }}
              className='fixed inset-0'
            >
              <div className='bg-black w-full h-full' style={{ opacity: 0.6 }} />
            </motion.div>
            <motion.div
              className={`fixed top-0 bottom-0 ${side === 'right' ? 'right-0' : 'left-0'} ${className}`}
              style={{ zIndex: 1001 }}
              initial={{ translateX: `${side === 'left' ? '-' : ''}100%` }}
              animate={{ translateX: '0%' }}
              exit={{ translateX: `${side === 'left' ? '-' : ''}100%` }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
              <div ref={slideOverRef} className='overflow-y-auto h-full flex flex-col bg-white shadow-lg p-4'>
                {children}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default SlideOver
