import AngleRightBIcon from 'icons/AngleRightBIcon'
import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  title: string | ReactNode
  overlineText?: string | ReactNode
  underlineText?: string | ReactNode
  secondaryText?: string | ReactNode
  icon?: ReactNode
  className?: string
  disabled?: boolean
  onClick?: () => void
  to?: string
  headingClassName?: string
  replaceTo?: boolean
}

const ListItem: FC<Props> = ({
  title,
  secondaryText,
  overlineText,
  underlineText,
  icon,
  disabled,
  onClick,
  className,
  headingClassName = '',
  to = '',
  replaceTo = false,
}) => {
  let TagName: FC<any> = ({ children, ...rest }) => (
    <Link {...rest} to={to}>
      {children}
    </Link>
  )

  if (!to || disabled)
    TagName = ({ children, ...rest }) => (
      <button {...rest} type={'button'} disabled={disabled}>
        {children}
      </button>
    )

  return (
    <TagName
      className={`
        ${overlineText || secondaryText ? 'py-4' : 'py-5'} 
        text-gray-900 border-gray-200 border rounded flex items-center px-4
        ${
          !disabled &&
          'focus:outline-none hover:bg-gray-100 transition ease-in-out duration-150 focus:border-secondary focus:shadow-outline-blue'
        }
        ${disabled && 'opacity-50 cursor-default'}
        ${className}
      `}
      to={to}
      onClick={onClick}
      replace={to && replaceTo}
    >
      {icon && <div className='mr-4 text-secondary w-6'>{icon}</div>}
      <div className='overflow-hidden mr-auto'>
        {overlineText && <div className='text-xs text-left text-gray-700'>{overlineText}</div>}
        <h5 className={`leading-5 ${headingClassName}`} title={typeof title === 'string' ? title : ''}>
          {title}
        </h5>
        {underlineText && <div className='text-xs text-left text-gray-700'>{underlineText}</div>}
      </div>
      {secondaryText ? <small className='mx-2 text-gray-700 whitespace-nowrap'>{secondaryText}</small> : null}
      <AngleRightBIcon size={16} className='text-black' />
    </TagName>
  )
}

export default ListItem
