import { gql } from '@apollo/client'

export default gql`
  fragment MeParts on User {
    id
    firebaseId
    privilegeLevel
    firstName
    lastName
    email
  }
`
