import { useBreadcrumbs } from 'providers/BreadcrumbsProvider'
import React, { DetailedHTMLProps, FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styles from './Breadcrumbs.module.css'

export interface BreadcrumbsProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ className }) => {
  const baseClass = `${styles.container} flex`
  const leftIconClass = 'mr-2.5 text-secondary w-5 h-5 flex justify-end items-center'

  const { breadcrumbs } = useBreadcrumbs()
  const breadcrumbsItems = useMemo(
    () =>
      breadcrumbs.map((breadcrumb, index, list) => {
        const lastItemInList = index === list.length - 1
        let LinkWrapper: FC<any> =
          !lastItemInList && breadcrumb.link
            ? ({ children, ...rest }) => (
                <Link to={breadcrumb.link || ''} {...rest}>
                  {children}
                </Link>
              )
            : ({ children, ...rest }) => <div {...rest}>{children}</div>

        let buttonClassName = 'flex items-center justify-center text-sm '
        if (index !== 0) buttonClassName += 'md:max-w-full max-w-xxs'
        if (!lastItemInList) buttonClassName += 'cursor-pointer hover:opacity-80'

        return (
          <div className='mr-2 flex items-center' key={index}>
            <LinkWrapper onClick={breadcrumb.onClick}>
              <span data-for='breadcrumb' data-tip={breadcrumb.title} className={buttonClassName}>
                {breadcrumb.iconLeft && <span className={leftIconClass}>{breadcrumb.iconLeft}</span>}
                <span className={index !== 0 ? 'md:max-w-full max-w-xxs truncate' : ''} title={breadcrumb.title}>
                  {breadcrumb.title}
                </span>
                {breadcrumb.iconRight && <span className='ml-3'>{breadcrumb.iconRight}</span>}
              </span>
              <ReactTooltip id='breadcrumb' />
            </LinkWrapper>
          </div>
        )
      }),
    [breadcrumbs]
  )

  return <div className={`${className} ${baseClass}`}>{breadcrumbsItems}</div>
}

export default Breadcrumbs
