import { gql } from '@apollo/client'

export default gql`
  fragment AttributeKeyParts on AttributeKey {
    id
    createdAt {
      formatted
    }
    name
    type
    description
    suffix
  }
`
