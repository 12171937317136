import { gql } from '@apollo/client'
import WorkspaceParts from 'graphql/fragments/WorkspaceParts'

export default gql`
  query Workspaces($first: Int!, $offset: Int!) {
    Workspaces(first: $first, offset: $offset) {
      id
      ...WorkspaceParts
    }
    WorkspacesCount
  }
  ${WorkspaceParts}
`
