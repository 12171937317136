import { useToolbars } from 'providers/ToolbarProvider'
import React, { DetailedHTMLProps, FC, useMemo } from 'react'
export interface ToolbarProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Toolbar: FC<ToolbarProps> = ({ className }) => {
  const baseClass = `flex inline-block`

  const { buttons } = useToolbars()
  const buttonsItems = useMemo(
    () => (
      <div className='flex mr-1'>
        {buttons.map((button, index) => (
          <div className='mr-2 flex items-center' key={index}>
            {button}
          </div>
        ))}
      </div>
    ),
    [buttons]
  )

  return <div className={`${className} ${baseClass}`}>{buttonsItems}</div>
}

export default Toolbar
