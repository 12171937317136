import Button, { ButtonProps } from 'base-components/Button'
import SlideOver from 'base-components/SlideOver'
import firebase from 'firebase/app'
import ChannelIcon from 'icons/ChannelIcon'
import ChartPieAltIcon from 'icons/ChartPieAltIcon'
import CodeBranchIcon from 'icons/CodeBranchIcon'
import FileNetworkIcon from 'icons/FileNetworkIcon'
import HomeIcon from 'icons/HomeIcon'
import KeySkeletonIcon from 'icons/KeySkeletonIcon'
import SettingIcon from 'icons/SettingIcon'
import SignoutIcon from 'icons/SignoutIcon'
import SitemapIcon from 'icons/SitemapIcon'
import TimesIcon from 'icons/TimesIcon'
import UnlockAltIcon from 'icons/UnlockAltIcon'
import { useAuth } from 'providers/AuthProvider'
import { useOrganizationModal } from 'providers/OrganizationModalProvider'
import React, { FC } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { getAdminRoute, getOrganizationRoute, getUserSignInRoute, getWorkspaceSettingsRoute } from 'routes'
import WorkspaceSelect from './WorkspaceSelect'
interface Props {
  open: boolean
  onClose: () => void
}

const NavigationSlideOver: FC<Props> = ({ open, onClose }) => {
  const { me } = useAuth()

  const { pathname } = useLocation()
  const NavigationButton: FC<
    {
      title: string
      icon: any
      isSelected?: boolean
      link?: string
      onClick?: () => void
    } & ButtonProps
  > = ({ title, isSelected, icon: Icon, link, onClick, ...rest }) => {
    const onButtonClick = () => {
      if (onClick) onClick()
      onClose()
    }

    return (
      <Button
        size='lg'
        iconLeft={<Icon className={isSelected ? 'text-gray-900' : 'text-secondary'} />}
        className='mb-2 w-full'
        variant={isSelected ? 'tertiary' : 'light'}
        to={link}
        onClick={onButtonClick}
        {...rest}
      >
        {title}
      </Button>
    )
  }

  const handleSignout = async () => {
    const toastId = toast.loading('Du loggas ut...')
    try {
      await firebase.auth().signOut()
      toast.success('Du är nu utloggad.', { id: toastId })
    } catch {
      toast.error('Utloggning misslyckades.', { id: toastId })
    }
  }

  const { openSelectOrgModal } = useOrganizationModal()

  return (
    <SlideOver side='right' open={open} onClose={onClose}>
      <div className='flex flex-col h-full w-96'>
        <div className='flex-1 flex flex-col'>
          <div className='flex justify-between items-center mb-8'>
            <h3>Meny</h3>
            <button
              onClick={onClose}
              className='w-12 h-12 rounded-lg bg-gray-100 flex items-center justify-center focus:outline-none focus:shadow-outline-blue'
            >
              <TimesIcon size={20} />
            </button>
          </div>

          <NavigationButton title='Startsida' icon={HomeIcon} isSelected={pathname === '/'} link='/' />
          <NavigationButton
            title='Organisationer'
            icon={SitemapIcon}
            isSelected={pathname.startsWith(getOrganizationRoute())}
            onClick={openSelectOrgModal}
          />
          <NavigationButton title='Samverkan' icon={ChannelIcon} link='/cooperation' />
          <NavigationButton title='Flöden' icon={CodeBranchIcon} disabled textRight='Ej lanserad' />
          <NavigationButton title='Statistikverktyg' icon={ChartPieAltIcon} disabled textRight='Ej lanserad' />
          <NavigationButton title='Simuleringar' icon={FileNetworkIcon} disabled textRight='Ej lanserad' />
        </div>

        {!me && (
          <>
            <WorkspaceSelect />
            <div className='h-4' />
            <Button
              className='justify-center w-full'
              variant='tertiary'
              size='md'
              iconLeft={<UnlockAltIcon />}
              to={getUserSignInRoute()}
              onClick={onClose}
            >
              Logga in (administratör)
            </Button>
          </>
        )}
        {me && (
          <div className='border-t border-gray-200 p-5 -m-4'>
            <div className='flex items-center mb-3'>
              <div className='flex flex-col flex-1'>
                <div className='text-gray-900 font-semibold text-base'>
                  {me.firstName} {me.lastName}
                </div>
                <div className='text-gray-500 text-sm'>
                  {me.privilegeLevel === 'SUPER_ADMIN' ? 'Superadministratör' : 'Standardbehörighet'}
                </div>
              </div>
              <Button onClick={handleSignout} iconLeft={<SignoutIcon />} variant='light' className='py-2' size='xs'>
                Logga ut
              </Button>
            </div>

            {/* <h6 className='mb-2'>Aktivt förbund</h6>
            <WorkspaceSelect /> */}
            <WorkspaceSelect />
            <div className='flex flex-col'>
              <Button
                onClick={onClose}
                to={getWorkspaceSettingsRoute()}
                size='xs'
                iconLeft={<SettingIcon />}
                variant='tertiary'
                className='w-full justify-center mt-2'
              >
                Förbundsinställningar
              </Button>
              {me.privilegeLevel === 'SUPER_ADMIN' && (
                <Button
                  className='justify-center w-full mt-2'
                  variant='light'
                  size='xs'
                  iconLeft={<KeySkeletonIcon />}
                  to={getAdminRoute()}
                  onClick={onClose}
                >
                  Administratörsinställningar
                </Button>
              )}
            </div>

            {/* <Button
              className='justify-center w-full mb-2'
              variant='tertiary'
              size='md'
              iconLeft={<SettingIcon />}
              to={getSettingsRoute()}
              onClick={onClose}
            >
              Inställningar
            </Button>
            <Button onClick={handleSignout} className='justify-center w-full' variant='light' size='md' iconLeft={<SignoutIcon />}>
              Logga ut
            </Button> */}
          </div>
        )}
      </div>
    </SlideOver>
  )
}

export default NavigationSlideOver
