import SelectOrganizationModal from 'components/SelectOrganizationModal'
import useOpenState from 'hooks/useOpenState'
import React, { createContext, FC, useCallback, useContext, useState } from 'react'

interface OrganizationModalValue {
  selectOrgModalOpen: boolean
  openSelectOrgModal: () => void
  closeSelectOrgModal: () => void
  onSelectOrgModalClose: (callback: () => () => void) => void
}

export const OrganizationModalContext = createContext<OrganizationModalValue>({
  selectOrgModalOpen: false,
  openSelectOrgModal: () => void 0,
  closeSelectOrgModal: () => void 0,
  onSelectOrgModalClose: () => void 0,
})

export const useOrganizationModal = () => useContext(OrganizationModalContext)

export const OrganizationModalProvider: FC = ({ children }) => {
  const [selectOrgModalOpen, openSelectOrgModal, closeSelectOrgModal] = useOpenState()
  const [onSelectOrgModalCloseCallback, onSelectOrgModalClose] = useState<() => void>(() => () => {})

  const onClose = useCallback(() => {
    onSelectOrgModalCloseCallback()
    closeSelectOrgModal()
  }, [onSelectOrgModalCloseCallback, closeSelectOrgModal])

  return (
    <OrganizationModalContext.Provider value={{ selectOrgModalOpen, openSelectOrgModal, closeSelectOrgModal, onSelectOrgModalClose }}>
      {children}
      <SelectOrganizationModal open={selectOrgModalOpen} onClose={onClose} />
    </OrganizationModalContext.Provider>
  )
}
