import Appsignal from '@appsignal/javascript'
import React, { Component } from 'react'
import ErrorScreen from './ErrorScreen'

const appsignal = new Appsignal({
  key: 'efcf0d51-a90f-4627-906b-de02a759e003',
  revision: process.env.REACT_APP_BUILD_ID || '0.0.6',
})

class ErrorBoundary extends Component<{}, { error: Error | null }> {
  constructor(props: {}) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: any) {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      appsignal.send(error)
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorScreen error={this.state.error} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
