import { gql } from '@apollo/client'
import StaffKeyParts from './StaffKeyParts'

export default gql`
  fragment StaffParts on Staff {
    id
    createdAt {
      formatted
    }
    position
    key {
      ...StaffKeyParts
    }
    numberOfEmployees
  }
  ${StaffKeyParts}
`
