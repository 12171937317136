import React, { createContext, FC, ReactNode, useContext, useState } from 'react'

interface ToolbarsProviderValue {
  buttons: ReactNode[]
  setToolbars: (buttons: ReactNode[]) => void
}

export const ToolbarsContext = createContext<ToolbarsProviderValue>({
  buttons: [],
  setToolbars: () => null,
})

export const useToolbars = () => useContext(ToolbarsContext)

export const ToolbarsProvider: FC = ({ children }) => {
  const [buttons, setToolbars] = useState<ReactNode[]>([])

  return <ToolbarsContext.Provider value={{ setToolbars, buttons }}>{children}</ToolbarsContext.Provider>
}
