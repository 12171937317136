import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Spinner from 'base-components/Spinner'
import ErrorBoundary from 'components/ErrorBoundary'
import LoadingPage from 'components/LoadingPage'
import firebase from 'firebase/app'
import 'firebase/auth'
import { AuthProvider } from 'providers/AuthProvider'
import { BreadcrumbsProvider } from 'providers/BreadcrumbsProvider'
import { OrganizationModalProvider } from 'providers/OrganizationModalProvider'
import { ToolbarsProvider } from 'providers/ToolbarProvider'
import { WorkspaceProvider } from 'providers/WorkspaceProvider'
import React, { lazy, Suspense } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import ReactDOM from 'react-dom'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { getAdminBaseRoute, getInvitationRoute } from 'routes'
import { QueryParamProvider } from 'use-query-params'
import App from './App'
import reportWebVitals from './reportWebVitals'

const AdminRoute = lazy(() => import('routes/admin/AdminRoute'))
const InvitationRoute = lazy(() => import('routes/InvitationRoute'))

const firebaseConfig = {
  apiKey: 'AIzaSyDcClmiikQc477mPXredTNUZ_5MfgyJSsg',
  authDomain: 'samordningskartan.firebaseapp.com',
  databaseURL: 'https://samordningskartan.firebaseio.com',
  projectId: 'samordningskartan',
  storageBucket: 'samordningskartan.appspot.com',
  messagingSenderId: '763232642606',
  appId: '1:763232642606:web:c429da98fb17737102a1bf',
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_GRAPHQL_URL || 'http://localhost:4001/graphql',
})

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier()

export const GlobalLoadingIndicator = () => {
  const status = useApolloNetworkStatus()
  if (status.numPendingQueries > 0) {
    return (
      <div className='p-5 fixed bottom-0 right-0 m-8 z-[1004]' style={{ zIndex: 1004 }}>
        <Spinner size={50} />
      </div>
    )
  } else {
    return null
  }
}

const authLink = setContext((_, { headers }) => {
  const idToken = localStorage.getItem('idToken')

  let workspace_slug = localStorage.getItem('workspace_slug') || 'test'
  if (window.location.pathname.indexOf('/admin/') !== -1) {
    workspace_slug = localStorage.getItem('admin_workspace_slug') || ''
  } else if (process.env.NODE_ENV === 'production' && window.location.hostname) {
    workspace_slug = window.location.hostname.split('.')[0]
  }

  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : '',
      workspace_slug,
    },
  }
})

const links = ApolloLink.from([authLink, link])

const client = new ApolloClient({
  link: links.concat(httpLink),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ToolbarsProvider>
        <BreadcrumbsProvider>
          <ApolloProvider client={client}>
            <GlobalLoadingIndicator />
            <AuthProvider>
              <Router>
                <WorkspaceProvider>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <OrganizationModalProvider>
                      <Suspense fallback={<LoadingPage />}>
                        <Switch>
                          <Route component={AdminRoute} path={getAdminBaseRoute()} />
                          <Route component={InvitationRoute} path={getInvitationRoute()} />
                          <Route component={App} path='/' />
                        </Switch>
                      </Suspense>
                    </OrganizationModalProvider>
                  </QueryParamProvider>
                </WorkspaceProvider>
              </Router>
            </AuthProvider>
          </ApolloProvider>
        </BreadcrumbsProvider>
      </ToolbarsProvider>
      <Toaster />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
