import Spinner from 'base-components/Spinner'
import React, { FC } from 'react'

const LoadingPage: FC = () => {
  return (
    <div className='min-h-screen flex-center'>
      <Spinner variant='primary' size={40} />
    </div>
  )
}

export default LoadingPage
