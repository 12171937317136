import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const ArrowLeftIcon = ({ size, ...props }: Props) => {
  if (size) {
    props.width = size;
    props.height = size;
  }

  return (
    <svg data-name="Layer 1" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M17 11H9.41l3.3-3.29a1 1 0 10-1.42-1.42l-5 5a1 1 0 00-.21.33 1 1 0 000 .76 1 1 0 00.21.33l5 5a1 1 0 001.42 0 1 1 0 000-1.42L9.41 13H17a1 1 0 000-2z"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
