import { gql } from '@apollo/client'
import WorkspaceParts from 'graphql/fragments/WorkspaceParts'

export default gql`
  query WorkspaceBySlug($slug: String!) {
    WorkspaceBySlug(slug: $slug) {
      id
      ...WorkspaceParts
    }
  }
  ${WorkspaceParts}
`
