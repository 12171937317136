import ServerDownImage from 'assets/server_down.svg'
import Button from 'base-components/Button'
import SyncIcon from 'icons/SyncIcon'
import React, { FC, useState } from 'react'

interface Props {
  error: Error | null
}

const ErrorUpdateMessage: FC<Props> = ({ error }) => {
  const [loading, setLoading] = useState(false)
  const handleUpdate = () => {
    setLoading(true)
    window.location.reload()
  }

  return (
    <div className='min-h-screen bg-gray-50 flex items-center justify-center p-12'>
      <div className='flex flex-col items-center max-w-2xl'>
        <img src={ServerDownImage} alt='Server down' />
        <h1 className='text-5xl text-center font-bold text-gray-800 mt-10'>
          {error?.name === 'ChunkLoadError' && 'Appen har uppdaterats'}
          {error?.name !== 'ChunkLoadError' && 'Någonting gick fel'}
        </h1>
        <p className='mt-6 text-xl text-gray-500 text-center'>
          {error?.name === 'ChunkLoadError' &&
            `
          Cruitive har uppdaterats och för att visa den sidan du försöker komma åt behöver du uppdatera webbläsaren. Klicka på "Ladda om
          sidan" nedan eller gör det direkt i webbläsaren.
        `}

          {error?.name !== 'ChunkLoadError' &&
            'Det uppstod ett problem och appen kraschade. Testa först att ladda om sidan och kontakta oss om det fortfarande inte fungerar.'}
        </p>
        <Button
          disabled={loading}
          onClick={handleUpdate}
          variant='primary'
          className='mt-8 max-w-lg w-full text-xl flex justify-center items-center'
        >
          <SyncIcon size={18} className={`mr-2 ${loading && 'animate-spin'}`} />
          Ladda om sidan
        </Button>
      </div>
    </div>
  )
}

export default ErrorUpdateMessage
