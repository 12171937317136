import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const AngleRightBIcon = ({ size, ...props }: Props) => {
  if (size) {
    props.width = size;
    props.height = size;
  }

  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M15.54 11.29L9.88 5.64a1 1 0 00-1.42 0 1 1 0 000 1.41l4.95 5L8.46 17a1 1 0 000 1.41 1 1 0 00.71.3 1 1 0 00.71-.3l5.66-5.65a1 1 0 000-1.47z"
      />
    </svg>
  );
};

export default AngleRightBIcon;
