import { useQuery } from '@apollo/client'
import firebase from 'firebase/app'
import { Me, MeParts } from 'generatedTypes'
import MeQuery from 'graphql/queries/MeQuery'
import React, { createContext, FC, useContext, useEffect, useState } from 'react'

interface AuthProviderValue {
  loading: boolean
  me?: MeParts
}

const AuthContext = createContext<AuthProviderValue>({
  loading: true,
  me: undefined,
})

const useAuth = () => useContext(AuthContext)

const AuthProvider: FC = ({ children }) => {
  const [loadingFirebaseUser, setLoadingFirebaseUser] = useState<boolean>(true)
  const [firebaseUser, setFirebaseUser] = useState<firebase.User>()

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken()
        localStorage.setItem('idToken', idToken)
        setFirebaseUser(user)
      } else {
        localStorage.removeItem('idToken')
        setFirebaseUser(undefined)
      }
      setLoadingFirebaseUser(false)
    })
    return unsubscribe
  })

  const { data, loading: loadingMe } = useQuery<Me>(MeQuery, { skip: !firebaseUser })
  const me = data?.Me

  return <AuthContext.Provider value={{ me, loading: loadingFirebaseUser || loadingMe }}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext, useAuth }
