import React, { createContext, FC, ReactNode, useContext, useState } from 'react'
import { useTitle } from 'react-use'

export interface Breadcrumb {
  title: string
  link?: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

interface BreadcrumbsProviderValue {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

export const BreadcrumbsContext = createContext<BreadcrumbsProviderValue>({
  breadcrumbs: [],
  setBreadcrumbs: () => null,
})

export const useBreadcrumbs = () => useContext(BreadcrumbsContext)

export const BreadcrumbsProvider: FC = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  useTitle(
    breadcrumbs?.length > 0
      ? breadcrumbs
          .map((b) => b.title)
          .reverse()
          .join(' - ')
      : 'Samordningskartan'
  )

  return <BreadcrumbsContext.Provider value={{ setBreadcrumbs, breadcrumbs }}>{children}</BreadcrumbsContext.Provider>
}
