import React, { PropsWithChildren, ReactElement, useContext, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import { SkeletonContext } from './Skeleton'

export interface SkeletonListProps<T> {
  /**
   * Unique identifier to persist list size in localStorage
   */
  id?: string
  /**
   * Typically the list element you want to render for each item
   */
  children: (item: T, key: number) => ReactElement
  /**
   * Iterable list
   */
  list: T[] | null
  /**
   * Enable/disable skeleton
   */
  loading?: boolean
  /**
   * Fallback number of skeleton items. Will be used on first load.
   */
  fallbackCount?: number
  /**
   * Fallback value/item/object
   */
  fallbackItem?: ReactElement | string | Partial<T> | Partial<Record<keyof T, any>>
  /**
   * Empty list render component
   */
  emptyState?: string | ReactElement
}

/**
 * Local list provider
 */
const SkeletonListContext = React.createContext<any>([])

/**
 * Skeleton list component to generate skeleton lists and persist list size between page loads.
 * **The list size is automatically saved to localStorage** as `skeleton.<id>` and will be used to
 * render the same amount of skeleton items on future page loads.
 *
 * Set `id` prop to an empty string to disable list count persistance.
 * @param props Skeleton list props
 */
function SkeletonList<T = any>({
  id,
  list,
  children,
  loading: localLoading,
  fallbackItem = {},
  fallbackCount = 3,
  emptyState = undefined,
}: PropsWithChildren<SkeletonListProps<T>>): ReactElement {
  const contextLoading = useContext(SkeletonContext)
  const [numItems, setValue] = useLocalStorage(`skeleton.${id}`)
  const loading = useMemo(() => localLoading ?? contextLoading, [localLoading, contextLoading])

  // Build mocked or loaded list
  const skeletonList = useMemo(() => {
    if (loading)
      return [...Array(numItems || fallbackCount)].map((_, key) => {
        return React.isValidElement(fallbackItem) ? { ...fallbackItem, key } : fallbackItem
      })
    else return list || []
  }, [list, loading, numItems, fallbackCount, fallbackItem])

  // Save list count to localStorage
  useEffect(() => {
    if (id) setValue(skeletonList.length)
  }, [skeletonList, setValue, id])

  return (
    <SkeletonContext.Provider value={loading}>
      <SkeletonListContext.Provider value={skeletonList}>
        <SkeletonListContext.Consumer
          children={(list) =>
            // Pass list item object to child
            list.map((item: T, key: number) => {
              return React.isValidElement(item) ? item : children?.(item, key)
            })
          }
        />
        {!loading && list?.length === 0 ? emptyState : null}
      </SkeletonListContext.Provider>
    </SkeletonContext.Provider>
  )
}

export default SkeletonList
