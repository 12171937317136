import NotFoundImage from 'assets/not_found.svg'
import Button from 'base-components/Button'
import HomeIcon from 'icons/HomeIcon'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  title?: string
  description?: string
}

const NotFound: FC<Props> = ({ title, description }) => {
  const history = useHistory()

  const handleGoHome = () => {
    history.push('/')
  }

  return (
    <div className='min-h-screen bg-gray-50 flex items-center justify-center p-12'>
      <div className='flex flex-col items-center max-w-2xl'>
        <img src={NotFoundImage} alt='Server down' />
        <h1 className='text-5xl text-center font-bold text-gray-800 mt-10'>{title ?? 'Sidan hittades inte'}</h1>
        <p className='mt-6 text-xl text-gray-500 text-center'>{description ?? <>Sidan du försöker hitta existerar inte.</>}</p>
        <Button onClick={handleGoHome} variant='primary' className='mt-8 max-w-lg w-full text-xl flex justify-center items-center'>
          <HomeIcon size={18} className={`mr-2`} />
          Gå till startsidan
        </Button>
      </div>
    </div>
  )
}

export default NotFound
