import { useEffect, useState } from 'react'
import { useLocation } from 'react-use'

function useSubdomain(): string | undefined {
  const location = useLocation()
  const [subdomain, setSubdomain] = useState<string>()

  useEffect(() => {
    if (location.hostname) {
      setSubdomain(location.hostname.split('.')[0])
    } else {
      setSubdomain(undefined)
    }
  }, [location.hostname])

  return subdomain
}

export default useSubdomain
