import React, { FC, ReactElement, SelectHTMLAttributes, useContext, useMemo } from 'react'

interface Props extends Omit<SelectHTMLAttributes<HTMLDivElement>, 'placeholder'> {
  /**
   * Skeleton placeholder text or component. The text is hidden but is used to define skeleton width.
   */
  placeholder?: ReactElement | string
  /**
   * Make skeleton full-width. Applies `display: block` on the element.
   */
  block?: boolean
  /**
   * Local loading state, if not used with `<SkeletonContext.Provider>`
   */
  loading?: boolean
}

/**
 * Provide skeleton loading context
 */
export const SkeletonContext = React.createContext(false)

/**
 * Skeleton component which tries to adapt to your content.
 * Probably most useful in combination with `<SkeletonContext.Provider value={loading}>...</SkeletonContext.Provider>`.
 * @param props Skeleton props
 */
const Skeleton: FC<Props> = ({ placeholder, children, className = '', block = false, loading: localLoading, ...rest }) => {
  const contextLoading = useContext(SkeletonContext)
  const blockClass = useMemo(() => (block ? 'block' : 'inline-flex'), [block])
  const loading = useMemo(() => localLoading ?? contextLoading, [localLoading, contextLoading])

  if (loading)
    if (React.isValidElement(placeholder))
      // Custom placeholder
      return <>{placeholder}</>
    else
      return (
        <span {...rest} className={`${blockClass} ${className}`} aria-busy='true' aria-label='Laddar innehåll'>
          <span className='relative animate-pulse h-full overflow-hidden'>
            <span className='invisible' aria-hidden='true'>
              {placeholder}
            </span>
            <span className='bg-gray-200 top-0 left-0 w-full h-full absolute' style={{ margin: '2px 0' }} />
          </span>
        </span>
      )
  else return <>{children}</>
}
export default Skeleton
