import { useQuery } from '@apollo/client'
import noDataImg from 'assets/no_data.svg'
import { Workspaces, WorkspacesVariables } from 'generatedTypes'
import WorkspacesQuery from 'graphql/queries/WorkspacesQuery'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { FC, useEffect, useMemo } from 'react'
import WorkspaceSelect from './WorkspaceSelect'

const SelectWorkspacePage: FC = () => {
  const { workspace: selectedWorkspace, changeWorkspace } = useWorkspace()
  const { data } = useQuery<Workspaces, WorkspacesVariables>(WorkspacesQuery, { variables: { first: 100, offset: 0 } })
  const workspaces = useMemo(() => data?.Workspaces || [], [data])

  useEffect(() => {
    if (workspaces.length === 1 && selectedWorkspace?.id !== workspaces[0].id && workspaces[0].slug) {
      changeWorkspace(workspaces[0].slug)
    }
    // eslint-disable-next-line
  }, [workspaces])

  return (
    <div className='min-h-screen bg-gray-50 flex items-center justify-center p-12'>
      <div className='flex flex-col items-center max-w-2xl'>
        <img src={noDataImg} alt='Inget förbund hittades' className='w-full max-w-[400px]' />
        <h1 className='text-5xl text-center font-bold text-gray-800 mt-10'>Förbundet hittades inte</h1>
        <p className='mt-6 mb-4 text-xl text-gray-500 text-center'>Ditt valda förbund hittades inte. Välj ett annat förbund nedan.</p>
        <WorkspaceSelect />
      </div>
    </div>
  )
}

export default SelectWorkspacePage
