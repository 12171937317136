import { useQuery } from '@apollo/client'
import Bullet from 'base-components/Bullet'
import ListItem from 'base-components/ListItem'
import Modal, { ModalProps } from 'base-components/Modal'
import Skeleton from 'base-components/Skeleton'
import SkeletonList from 'base-components/SkeletonList'
import { RootOrganizations, RootOrganizationsVariables } from 'generatedTypes'
import RootOrganizationsQuery from 'graphql/queries/RootOrganizationsQuery'
import TimesIcon from 'icons/TimesIcon'
import React, { FC, useMemo } from 'react'
import { getOrganizationMapRoute } from 'routes'

interface Props extends ModalProps {}

const SelectOrganizationModal: FC<Props> = ({ ...rest }) => {
  const { data, loading } = useQuery<RootOrganizations, RootOrganizationsVariables>(RootOrganizationsQuery)
  const organizations = useMemo(() => data?.RootOrganizations || [], [data])

  return (
    <Modal className='w-full max-w-lg max max-h-full overflow-auto' {...rest}>
      <div className='w-full flex flex-col'>
        <div className='flex justify-between mb-2'>
          <h4 className='mt-2 mb-6'>Välj organisation</h4>
          <button
            onClick={rest.onClose}
            className='w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center hover:bg-gray-200 focus:outline-none focus:shadow-outline-blue transition-colors'
          >
            <TimesIcon size={18} />
          </button>
        </div>

        {organizations.length === 0 && !loading && (
          <div className='text-gray-500 text-center text-lg mb-4'>Det finns inga organisationer på ditt förbund</div>
        )}

        <SkeletonList
          id='RootOrganizations'
          loading={loading}
          list={organizations}
          fallbackCount={6}
          fallbackItem={<ListItem title={<Skeleton placeholder='Linköpings kommun' />} icon={<Bullet size='md' color={'#AAA'} />} />}
        >
          {(organisation, key) => (
            <ListItem
              key={key}
              title={organisation?.name ?? ''}
              icon={<Bullet size='md' color={organisation?.primaryColor!} />}
              to={getOrganizationMapRoute(organisation?.slug)}
              className='w-full mb-2 border-none bg-gray-100 hover:bg-tertiary'
              headingClassName='font-normal'
              onClick={rest.onClose}
            />
          )}
        </SkeletonList>
      </div>
    </Modal>
  )
}

export default SelectOrganizationModal
