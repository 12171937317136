import Spinner from 'base-components/Spinner'
import AppBar from 'components/AppBar'
import LoadingPage from 'components/LoadingPage'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  getCooperationRoute,
  getOrganizationMapRoute,
  getOrganizationRoute,
  getStartRoute,
  getStatisticsRoute,
  getUserRegisterRoute,
  getUserSignInRoute,
  getWorkspaceSettingsRoute,
} from 'routes'
import './styles/tailwind.output.css'

const StartRoute = lazy(() => import('routes/StartRoute'))
const SignupRoute = lazy(() => import('routes/SignupRoute'))
const SignInRoute = lazy(() => import('routes/SignInRoute'))
const StatisticsRoute = lazy(() => import('routes/StatisticsRoute'))
const OrganizationMapRoute = lazy(() => import('routes/OrganizationMapRoute'))
const OrganizationRoute = lazy(() => import('routes/OrganizationRoute'))
const WorkspaceSettingsRoute = lazy(() => import('routes/workspace-settings/WorkspaceSettingsRoute'))
const CooperationRoute = lazy(() => import('routes/CooperationRoute'))

function App() {
  const { workspace } = useWorkspace()

  return (
    <div className='min-h-screen bg-white flex flex-col'>
      <AppBar />
      {!workspace && (
        <div className='flex-center flex-1'>
          <Spinner variant='primary' size={40} />
        </div>
      )}
      {workspace && (
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route component={StatisticsRoute} path={getStatisticsRoute()} />
            <Route component={OrganizationMapRoute} path={getOrganizationMapRoute(':slug')} exact={true} />
            <Route component={OrganizationRoute} path={getOrganizationRoute(':slug', ':unitSlug')} />
            <Route component={OrganizationRoute} path={getOrganizationRoute(':slug')} />
            <Route component={CooperationRoute} path={getCooperationRoute()} />
            <Route component={WorkspaceSettingsRoute} path={getWorkspaceSettingsRoute()} />
            <Route component={SignupRoute} path={getUserRegisterRoute()} />
            <Route component={SignInRoute} path={getUserSignInRoute()} />
            <Route component={StartRoute} path={getOrganizationRoute()} />
            <Route component={StartRoute} path={getStartRoute()} exact={true} />
            <Redirect to='/' />
          </Switch>
        </Suspense>
      )}
    </div>
  )
}

export default App
