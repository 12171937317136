import React, { FC, useMemo } from 'react'
import './Spinner.css'

interface Props {
  variant?: string
  size?: number
  className?: string
}

const Spinner: FC<Props> = ({ variant = 'white', size = 30, className = '' }) => {
  const colorClass = useMemo(() => {
    if (variant === 'white') {
      return 'text-white'
    } else if (variant === 'light') {
      return 'text-light'
    } else if (variant === 'dark') {
      return 'text-dark'
    } else if (variant === 'primary') {
      return 'text-primary'
    } else if (variant === 'secondary') {
      return 'text-secondary'
    } else if (variant === 'tertiary') {
      return 'text-tertiary'
    }
  }, [variant])

  return (
    <svg style={{ width: size, height: size }} className={`${className} ${colorClass} spinner`} viewBox='0 0 50 50'>
      <circle className='path stroke-current' cx='25' cy='25' r='20' fill='none' strokeWidth='5'></circle>
    </svg>
  )
}

export default Spinner
