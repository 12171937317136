import { gql } from '@apollo/client'

export default gql`
  fragment WorkspaceParts on Workspace {
    id
    name
    description
    slug
    createdAt {
      formatted
    }
  }
`
