import { gql } from '@apollo/client'
import MeParts from 'graphql/fragments/MeParts'

export default gql`
  query Me {
    Me {
      id
      ...MeParts
    }
  }
  ${MeParts}
`
