import { useApolloClient, useQuery } from '@apollo/client'
import { AnimatePresence, motion } from 'framer-motion'
import { WorkspaceParts, Workspaces, WorkspacesVariables } from 'generatedTypes'
import WorkspacesQuery from 'graphql/queries/WorkspacesQuery'
import useOpenState from 'hooks/useOpenState'
import CheckIcon from 'icons/CheckIcon'
import { useWorkspace } from 'providers/WorkspaceProvider'
import React, { FC, useMemo } from 'react'
import { useLayer } from 'react-laag'
import { useMeasure } from 'react-use'

interface Props {}

const WorkspaceSelect: FC<Props> = () => {
  const { workspace: selectedWorkspace, changeWorkspace } = useWorkspace()

  const [selectOpen, openSelect, closeSelect] = useOpenState()
  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: selectOpen,
    auto: true,
    triggerOffset: 5,
    onOutsideClick: closeSelect,
    onDisappear: closeSelect,
    placement: 'bottom-center',
  })

  const { data } = useQuery<Workspaces, WorkspacesVariables>(WorkspacesQuery, { variables: { first: 100, offset: 0 } })
  const workspaces = useMemo(() => data?.Workspaces || [], [data])

  const client = useApolloClient()
  const handleChangeWorkspace = (workspace: WorkspaceParts) => () => {
    if (workspace.slug) {
      changeWorkspace(workspace.slug)
      client.clearStore()
    }
  }

  const [ref, { width }] = useMeasure()

  return (
    <>
      <div className='w-full' ref={ref as any}>
        <button
          {...triggerProps}
          onClick={selectOpen ? closeSelect : openSelect}
          className='form-select bg-gray-100 text-left pl-4 pr-8 rounded-md w-full flex-none truncate h-[44px] text-sm border border-gray-200'
        >
          {selectedWorkspace?.name}
          {!selectedWorkspace?.name && <span className='text-gray-500'>Välj ett förbund..</span>}
        </button>
      </div>
      {renderLayer(
        <AnimatePresence>
          {selectOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.1 }}
              className='z-[1003] bg-white shadow-lg overflow-hidden rounded-md divide-y divide-gray-200 flex flex-col clickaway-ignore'
              {...layerProps}
              style={{ ...layerProps.style, width: width || 300 }}
            >
              {workspaces.map((workspace) => (
                <button
                  key={workspace.id}
                  onClick={handleChangeWorkspace(workspace)}
                  className='py-4 px-4 hover:bg-gray-100 text-sm text-left outline-none focus:outline-none flex items-center justify-between truncate'
                >
                  <div>{workspace.name}</div>
                  <div className='relative w-4 h-4'>
                    <motion.div
                      initial={{ opacity: 0 }}
                      exit={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className='w-4 h-4 border-2 border-gray-200 flex-center absolute rounded-full'
                    />
                    <AnimatePresence>
                      {workspace.id === selectedWorkspace?.id && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0 }}
                          exit={{ opacity: 0, scale: 0 }}
                          animate={{ opacity: 1, scale: 1 }}
                          className='w-4 h-4 bg-green-400 flex-center absolute rounded-full'
                        >
                          <CheckIcon size={16} className='text-white' />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

export default WorkspaceSelect
