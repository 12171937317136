import { gql } from '@apollo/client'

export default gql`
  fragment StaffKeyParts on StaffKey {
    id
    createdAt {
      formatted
    }
    # type
    name
    description
  }
`
