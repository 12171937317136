import esfLogo from 'assets/esf.svg'
import logo from 'assets/logo.svg'
import Breadcrumbs from 'base-components/Breadcrumbs'
import Button from 'base-components/Button'
import Toolbar from 'base-components/Toolbar'
import useOpenState from 'hooks/useOpenState'
import ArrowLeftIcon from 'icons/ArrowLeftIcon'
import BarsIcon from 'icons/BarsIcon'
import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import NavigationSlideOver from './NavigationSlideOver'

const AppBar: FC = () => {
  const { pathname } = useLocation()
  const [navigationSlideOverOpen, openNavigationSlideOver, closeNavigationSlideOver] = useOpenState()

  return (
    <div className='h-24 flex items-center justify-between p-4 w-full'>
      <div className='flex justify-between w-full overflow-hidden items-center'>
        {pathname === '/' && (
          <>
            <Link to='/'>
              <img src={logo} width={160} alt='Samordningsförbundet logotyp' />
            </Link>
            <Link to='/' className='ml-auto mr-8' title='Det här projektet medfinansieras av europeiska socialfonden (ESF)'>
              <img src={esfLogo} width={40} alt='ESF logotyp' />
            </Link>
          </>
        )}
        {pathname !== '/' && (
          <>
            <Button variant='light' to='/'>
              <ArrowLeftIcon size={20} />
            </Button>
            <Breadcrumbs className='mx-8 flex-1 overflow-hidden' />
            <Toolbar className='ml-auto mr-2' />
          </>
        )}
      </div>

      {/* <WorkspaceSelect /> */}
      <button
        onClick={openNavigationSlideOver}
        className='p-3 rounded-lg bg-gray-100 flex items-center justify-center focus:outline-none focus:shadow-outline-blue'
      >
        <BarsIcon size={20} />
      </button>
      <NavigationSlideOver open={navigationSlideOverOpen} onClose={closeNavigationSlideOver} />
    </div>
  )
}

export default AppBar
