import React, { FC, useMemo } from 'react'

interface Props {
  size?: 'sm' | 'md'
  color?: string
  className?: string
  onClick?: () => void
}

const Bullet: FC<Props> = ({ size = 'md', color, className, onClick }) => {
  const baseClass = 'rounded-full'

  const sizeClass = useMemo(() => {
    if (size === 'sm') {
      return 'w-3 h-3'
    }

    if (size === 'md') {
      return 'w-4 h-4'
    }
  }, [size])

  return <div onClick={onClick} style={{ backgroundColor: color }} className={`${className} ${baseClass} ${sizeClass}`} />
}

export default Bullet
