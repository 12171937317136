import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const UnlockAltIcon = ({ size, ...props }: Props) => {
  if (size) {
    props.width = size;
    props.height = size;
  }

  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12 13a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zm5-4H9V7a3 3 0 015.12-2.13 3.08 3.08 0 01.78 1.38 1 1 0 101.94-.5 5.09 5.09 0 00-1.31-2.29A5 5 0 007 7v2a3 3 0 00-3 3v7a3 3 0 003 3h10a3 3 0 003-3v-7a3 3 0 00-3-3zm1 10a1 1 0 01-1 1H7a1 1 0 01-1-1v-7a1 1 0 011-1h10a1 1 0 011 1z"
      />
    </svg>
  );
};

export default UnlockAltIcon;
