import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const KeySkeletonIcon = ({ size, ...props }: Props) => {
  if (size) {
    props.width = size;
    props.height = size;
  }

  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M21 4.41l.71-.7a1 1 0 10-1.42-1.42l-1.4 1.41-2.83 2.83-6.31 6.3a5 5 0 101.42 1.42l5.59-5.6 2.12 2.13a1 1 0 101.41-1.42l-2.12-2.12 1.42-1.41.7.7a1 1 0 101.42-1.41zM7 20a3 3 0 113-3 3 3 0 01-3 3z"
      />
    </svg>
  );
};

export default KeySkeletonIcon;
