import React, { DetailedHTMLProps, FC, ReactElement, ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'

export interface ButtonProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  variant?: BaseVariants
  iconLeft?: ReactNode
  iconRight?: ReactNode
  disabled?: boolean
  textRight?: ReactElement | string
  to?: any
  rel?: string
}

const Button: FC<ButtonProps> = ({
  children,
  size = 'md',
  variant = 'primary',
  iconLeft,
  iconRight,
  textRight,
  className = '',
  ...rest
}) => {
  const baseClass = 'items-center border border-transparent rounded-md focus:outline-none transition ease-in-out duration-150 flex'

  const { buttonSizeClass, leftIconClass, rightIconClass } = useMemo(() => {
    if (size === 'xs') {
      return { buttonSizeClass: 'p-3 text-xs', leftIconClass: 'mr-2.5 w-4 h-4', rightIconClass: 'ml-2.5 w-4 h-4' }
    }

    if (size === 'sm') {
      return { buttonSizeClass: 'p-3 text-sm', leftIconClass: 'mr-2.5 w-4 h-4', rightIconClass: 'ml-2.5 w-4 h-4' }
    }

    if (size === 'md') {
      return { buttonSizeClass: 'p-3 text-sm', leftIconClass: 'mr-2.5 w-5 h-5', rightIconClass: 'ml-2.5 w-5 h-5' }
    }

    if (size === 'lg') {
      return { buttonSizeClass: 'p-5 text-base', leftIconClass: 'mr-4 w-6 h-6', rightIconClass: 'ml-4 w-6 h-6' }
    }

    return {}
  }, [size])

  const variantClass = useMemo(() => {
    if (variant === 'white') {
      return 'bg-white 6 focus:border-gray-100 focus:shadow-outline-gray-10'
    }

    if (variant === 'light') {
      return 'bg-gray-100 text-gray-900 focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'dark') {
      return 'bg-gray-900 text-white focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'primary') {
      return 'bg-primary text-gray-900 focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'secondary') {
      return 'bg-secondary text-gray-900 focus:border-gray-100 focus:shadow-outline-gray-100'
    }

    if (variant === 'tertiary') {
      return 'bg-tertiary text-gray-900 focus:border-gray-100 focus:shadow-outline-gray-100'
    }
  }, [variant])

  const disabledClass = useMemo(() => (rest.disabled ? 'opacity-50 cursor-default' : 'hover:opacity-75'), [rest.disabled])
  const TagName: FC<any> = ({ to, children, ...rest }: any) => {
    if (to !== undefined)
      if (to.startsWith('http'))
        return (
          <a href={to} target='_blank' rel='noreferrer noopener' {...rest}>
            {children}
          </a>
        )
      else
        return (
          <Link to={to} {...rest}>
            {children}
          </Link>
        )
    else return <button {...rest}>{children}</button>
  }

  return (
    <TagName {...rest} className={`${baseClass} ${buttonSizeClass} ${variantClass} ${disabledClass} ${className}`}>
      {iconLeft && <span className={leftIconClass}>{iconLeft}</span>}
      {children}
      {textRight ? <span className={'block text-xs ml-auto'}>{textRight}</span> : null}
      {iconRight && <span className={rightIconClass}>{iconRight}</span>}
    </TagName>
  )
}

export default Button
