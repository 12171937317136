export const getUserSignInRoute = () => '/logga-in'
export const getUserRegisterRoute = () => '/registrera'

// /organisationer/:orgSlug/enhet/:unitSlug
export const getOrganizationRoute = (orgSlug?: string, unitSlug?: string) => {
  const slugs = ['/organisationer']
  if (orgSlug) slugs.push(orgSlug)
  if (unitSlug) slugs.push(unitSlug)
  return slugs.join('/')
}

type SettingsPage = 'organisationer' | 'malgrupper' | 'attribut' | 'yrkesroller' | 'samverkansforum' | 'anvandare'
export const getWorkspaceSettingsRoute = (page?: SettingsPage, adminWorkspaceId?: string) => {
  if (adminWorkspaceId) {
    return `/admin/forbund/${adminWorkspaceId}${page ? `/${page}` : ''}`
  } else {
    return `/installningar${page ? `/${page}` : ''}`
  }
}

type AdminPage = 'forbund' | 'anvandare'
export const getAdminRoute = (page?: AdminPage) => `/admin${page ? `/${page}` : ''}`

export const getOrganizationMapRoute = (orgSlug?: string, unitSlug?: string) => {
  const slugs = ['/organisationer']
  if (orgSlug) slugs.push(orgSlug)
  slugs.push('karta')
  return slugs.join('/')
}

export const getStatisticsRoute = () => '/statistik'

export const getInvitationRoute = () => '/inbjudan/:invitationId'

export const getHelpRoute = () => '/hjalp'

export const getStartRoute = () => '/'

export const getCooperationRoute = () => '/cooperation'

export const getAdminBaseRoute = () => '/admin'
