import { gql } from '@apollo/client'
import AttributeKeyParts from './AttributeKeyParts'

export default gql`
  fragment OrganizationAttributeParts on Attribute {
    id
    value
    position
    key {
      ...AttributeKeyParts
    }
  }
  ${AttributeKeyParts}
`
