import { gql } from '@apollo/client'
import OrganizationAttributeParts from './OrganizationAttributeParts'
import StaffParts from './StaffParts'

export default gql`
  fragment OrganizationParts on Organization {
    id
    name
    description
    shortDescription
    website
    postalAddress
    visitingAddress
    contactPerson
    phoneNumber
    email
    numberOfUnits
    numberOfCooperations
    slug
    primaryColor
    secondaryColor
    children {
      id
    }
    targetGroups {
      id
      name
      description
    }
    attributes {
      ...OrganizationAttributeParts
    }
    staff {
      ...StaffParts
    }
    ancestors {
      id
      slug
      name
      rootOrganization {
        id
        primaryColor
      }
    }
    rootOrganization {
      id
      name
      slug
      primaryColor
      secondaryColor
    }
    cooperations {
      id
      name
    }
  }
  ${OrganizationAttributeParts}
  ${StaffParts}
`
